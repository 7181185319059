import request from '@/auth/jwt/request'

/**
 * @description 业务管理 -- 业务管理列表
 * @param {Object} param params {Object} 传值参数
 */
export const zixunListApi = params => { return request('website-zixun/list', 'get', params) }

/**
 * @description 业务管理 -- 业务分类
 * @param {Object} param params {Object} 传值参数
 */
export const zixunCateSelectApi = params => { return request('website-zixun-category/select', 'get', params) }

/**
 * @description 业务管理 -- 增加修改业务管理
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const zixunSaveApi = data => { return request('website-zixun/save', 'post', {}, data) }

/**
 * @description 业务管理 -- 业务状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const zixunStatusApi = params => { return request('website-zixun/status', 'get', params) }

/**
 * @description 业务管理 -- 业务详情
 * @param {Object} param params {Object} 传值参数
 */
export const zixunInfoApi = params => { return request('website-zixun/info', 'get', params) }
/**
 * @description 业务管理 -- 删除业务
 * { id = x }
 */
export const zixunDeleteApi = data => { return request('website-zixun/delete', 'delete', data) }